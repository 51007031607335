import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).autocomplete({
      source: this.element.dataset.source,
      minLength: 3,
      select: (e, ui) => {
        e.target.closest('form').querySelector('[name=target]').value = ui.item.id
      }
    });
  }
}

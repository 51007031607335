// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DatePicker from './src/datepicker'

document.addEventListener('turbo:load', function() {
  dataLayer.push({ event: 'pageView', virtualUrl: window.location.href }); // so GTM tags can fire on link navigation

  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });
  new DatePicker();
});
